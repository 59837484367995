/* news Letter start */
h6.Newsletter-heading {
  font-size: 12px;
  font-family: "GT Walsheim Pro";
  font-weight: 500;
}

h3.subscribe-Newsletter-heading {
  font-size: 26px;
  font-family: "GT Walsheim Pro";
  font-weight: 600;
}

p.subscribe-paragraph-heading {
  font-size: 12px;
  font-family: "GT Walsheim Pro";
  font-weight: 500;
}

.footer .brdr_right_line {
  height: 100%;
  border-right: 1px solid;
}

@media only screen and (max-width: 787px) {
  .newsletter .newsletter-bg {
    background: url("../img/newsBg.png");
    background-color: #785d2f;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 25px;
    margin: auto !important;
  }

  .newsletter {
    margin-bottom: -125px;
    margin-right: 0px !important;
  }

  .footer {
    background-color: #63402b;
  }

  .footer-1-content-wrapper,
  .footer-widget-2,
  .footer-widget-3 {
    border-right: unset !important;
  }

  .bt_news_letter {
    font-size: 10px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .about-us {
    padding: 0px 0 !important;
  }

  .common-banner .url-container {
    padding: 40px 0;
  }

  .about-us-text {
    margin-top: 1rem !important;
  }

  .about-us-progressbar {
    margin-top: 0;
  }

  .about-us .img-control {
    height: auto;
  }

  .main-div-container {
    margin-top: 1rem;
  }

  .border-color {
    padding: 26px;
  }
}

.newsletter .bt-susbcribe {
  border-radius: 3px;
  font-family: "Poppins-Regular";
  font-weight: 400;
}

.newsletter .newsletter-bg {
  background: url("../img/newsBg.png");
  /* background-color: #785d2f; */
  border-radius: 14px;
  padding: 25px;
  background-size: cover !important;
  background-repeat: no-repeat;
  padding-top: 60px;
  padding-bottom: 60px;
  align-items: center;
}

.newsletter {
  margin-bottom: -125px;
  /* margin-right: 100px; */
}

.newsletter-input-wrapper {
  background: #fff;
  padding: 6px;
  display: flex;
  border-radius: 5px;
}

.newsletter-input-wrapper input {
  border: 0px;
  font-family: "Poppins-Regular";
  color: #878787;
  font-size: 16px;
}

.newsletter-input-wrapper button {
  font-family: "Poppins-Regular";
  font-weight: 600;
  border-radius: 5px;
  padding: 12px 30px;
}

.newsletter .content-wrapper {
  color: #fff;
}

/* button.btn {
  border-radius: 5px;
  background-image: linear-gradient(to right, #543828, #7d4f38);
  color: white;
  font-size: 12px;
  font-family: "Poppins-SemiBold";
} */
ul.footer-quick-link2 {
  list-style: none;
  padding: 0px;
  margin: 0;
  font-family: "GT Walsheim Pro";
  font-size: 11px;
}

/* .footer .quick_link {
  text-align: center;
} */

.footer .font_ico_footer {
  font-size: 14px;
}

.footer .font_ico_footer:hover {
  text-decoration: underline;
}

/* button.btn:hover {
    color: #fff;
} */
/* NewsLetter End  */

/* / footer css starts here / */
.footer {
  /* background: #b99455;  */
  background-image: url("../img/footerBg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 200px;
}

footer ul {
  list-style: none;
  padding: 0px;
  margin: 0;
  font-family: "GT Walsheim Pro";
  font-size: 13px;
  margin-bottom: 28px;
}

footer.footer h5 {
  color: #fff;
  margin-bottom: 15px;
}

.footer .res-px-0 {
  padding-left: 15px !important;
}

/* .footer .footer-res-amrgin {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
} */

.footer-links-wrapper li {
  line-height: 2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.footer-links-wrapper li a {
  color: #fff;
  font-family: "GT Walsheim Pro";
}

.footer-links-wrapper li i {
  color: #fff;
  /* font-family: "GT Walsheim Pro"; */
}

.footer-links-wrapper1 {
  display: flex;
}

.footer-links-wrapper1 li {
  line-height: 2;
}

.footer-links-wrapper1 li a {
  color: #fff;
  font-family: "GT Walsheim Pro";
}

ul.footer-quick-link1 {
  font-size: 11px;
  font-family: "GT Walsheim Pro";
}

.footer-1-content-wrapper {
  /* border-right: 1px solid #659194; */
  padding: 0 1rem;
}

.footer .brdr_right_white {
  border-right: 2px solid #ffffff2e;
  /* height: 100%; */
  height: 120px;
  margin: auto;
  padding-left: 7px;
  position: relative;
  top: -15px;
}

.footer .set_footer_set {
  align-items: center;
  align-self: center;
}

.footer-widget-2 {
  /* border-right: 1px solid #659194; */
  padding: 0 1rem;
}

.footer-widget-3 {
  /* border-right: 1px solid #659194; */
  padding: 0 1rem;
  padding-right: 17px;
}

.footer-widget-4 {
  padding: 0 1rem;
}

.footer-links-wrapper ul.social-links li {
  margin: 0px 21px 0px 0px;
}

.footer-links-wrapper {
  font-size: 14px;
  font-family: "GT Walsheim Pro";
}

ul.social-links li {
  list-style: none;
  font-size: 25px;
}

.copy-right-wrapper h6 {
  font-size: 12px;
  text-align: end;
  /* / line-height: 2; / */
}

.copy-right-wrapper h6 a {
  color: #fff;
  font-family: "GT Walsheim Pro";
}

.copy-right-wrapper p {
  margin-bottom: 0px;
  font-size: 12px;
  font-family: "GT Walsheim Pro";
  color: #fff;
}

.copy-right-wrapper {
  padding: 20px 0px;
  border-top: 1px solid #ffffff45;
}

.footer-1-content-wrapper p {
  font-family: "GT Walsheim Pro";
  margin-top: 10px;
  color: #fff;
  font-size: 11px;
}

/* / footer css ends here / */
