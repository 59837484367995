@media only screen and (max-width: 991px) {
	.about-us-text {
		margin-top: 10px !important;
	}

	.about-us {
		padding: 20px 0px !important;
	}

	.about-us .img-control {
		height: auto !important;
	}

	/* display: none; */
	/* .plus {
    margin-left: 24px !important;
    font-size: 20px;
    margin-top: 33px;
    margin-bottom: 39px;
    color: #3f2d26 !important;
  } */
}

@media only screen and (max-width: 1199px) {
	header .border_bottom_line_shine {
		margin-top: 0.5rem;
	}

	.plus {
		top: 12px !important;
		right: 29px !important;
	}

	.btn {
		border-radius: 0px;
		background-image: linear-gradient(to right, #543828, #7d4f38);
		color: white;
		font-weight: 400;
		font-size: 11px;
		padding: 12px 25px !important;
		font-family: "GT Walsheim Pro";
	}
}

@media only screen and (max-width: 991px) {
	.plus {
		top: 3px !important;
		right: 17px !important;
	}
}

@media only screen and (max-width: 767px) {
	.plus {
		top: 20% !important;
		right: 30% !important;
	}

	.rezzz_nonw {
		display: none;
	}

	.add_card_new_details {
		margin-top: 3.5rem;
	}
}

@media only screen and (max-width: 480px) {
	.modal-body p.summary-paragraph-fees {
		text-align: left !important;
	}

	.Billing_Details_Page p.my-card-handler {
		margin-top: 4px;
		font-size: 12px;
		/* margin-right: 15px;
    margin-left: 14px; */
	}

	.Billing_Details_Page p.use-default {
		font-size: 12px;
		margin-left: 0;
		margin-top: 37px;
	}

	.Billing_Details_Page .res_sml_save_crd {
		font-size: 20px;
	}

	.text_res_center_appointment {
		text-align: center !important;
	}

	.Billing_Details_Page p.main-head-card-number {
		font-size: 20px;
	}

	.Billing_Details_Page .rezzz_nonw {
		display: none;
	}
}

.About-Us-Page .about-us-progressbar {
	margin-top: 0 !important;
}

billing-details img.image-fluid {
	height: 69% !important;
}

/* Home About  start*/

/* .text-price {
  color: none !important;
} */

.progress-bar-main-text {
	margin-top: 30px;
}

.About-Us-Page .nav-tabs {
	border-bottom: 1px solid #dee2e6;
	display: inline-block;
	width: auto;
	padding-right: 10rem;
}

.about-us {
	padding: 100px 0px;
}

img.quote-image {
	margin-bottom: 30px;
}

p.quote {
	color: #363636;
	font-size: 18px;
	font-family: "GT Walsheim Pro";
	justify-content: center;
	padding: 0px 25px;
	padding-right: 0 !important;
	margin-right: 0 !important;
}

.border-color {
	border: 2px solid #88563b;
	padding: 34px;
	padding-top: 54px;
	padding-bottom: 44px;
}

.About-Us-Page .about_bottom_sec_head {
	color: #543828;
}

.About-Us-Page .author {
	margin-top: 3rem;
}

/* .about-us-progressbar {
  margin-top: -200px;
} */
.text-main-heading {
	color: #88563b;
	font-size: 18px;
	font-family: "Helvetica";
}

.about-us .tags-title {
	color: #88563b;
	font-family: "Helvetica";
	font-size: 14px;
}

/* .about-us .about-shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.about-us .title {
  font-size: 32px;
  color: #3f2d26;
  font-family: "GT Walsheim Pro";
} */
/* .about-us .para {
  color: #a89a93;
  font-family: "GT Walsheim Pro";
  font-size: 18px;
}

.about-us .points {
  color: #30231d;
  font-family: "GT Walsheim Pro";
  font-size: 16px;
} */

/* .about-us .home-icon {
  color: #88563b;
  margin-right: 15px;
  font-size: 20px;
}

.about-us .btn-container {
  margin-bottom: 90px;
  margin-top: 35px;
} */

.about-us-title {
	color: #3f2d26;
	font-size: 28px;
	font-family: "GT Walsheim Pro";
}

.about-us .img-control {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.about-us .img-control img {
	width: 100%;
	height: 100%;
}

.CircularProgressbar .CircularProgressbar-path {
	stroke: #88563b !important;
	stroke-linecap: round;
	transition: stroke-dashoffset 0.5s ease 0s;
}

.progreeBar-text {
	justify-content: center;
	text-align: center;
	margin: 18px;
}

.text-heading-2 {
	justify-content: center;
	text-align: center;
	font-size: 18px;
	font-family: "GT Walsheim Pro";
	color: #000000;
}

p.para {
	color: #a89a93;
	font-size: 16px;
	font-family: "GT Walsheim Pro";
}

.plus {
	font-size: 20px;
	position: absolute;
	color: #3f2d26 !important;
	top: 20px;
	font-size: 25px;
	right: 34px;
}

.main-div-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	align-items: center;
}

.progress-bar-controller {
	width: 50%;
}

.tile {
	width: 100%;
	margin: 0px auto;
}

#tile-1 .tab-pane {
	padding: 15px;
	padding-left: 0.25rem;
	/* height: 80px; */
	padding-top: 2rem;
}

.blockquote {
	padding: 20px;
}

.quote {
	font-size: 1.4rem;
	/* max-width: 400px; */
	line-height: 1.4;
	font-style: italic;
}

.author {
	/* float: right; */
	text-align: right;
	font-size: 16px;
	font-weight: bold;
	font-family: "Signerica_Fat" !important;
}

h2.title-second-text {
	font-family: "GT Walsheim Pro";
	font-size: 30px;
	color: #3f2d26;
}

.quote-icon {
	margin-bottom: 10px;
}

.blockquote hr {
	margin-top: 50px;
}

section.about-us button.tabs_btn.active {
	color: #88563b;
	border-bottom: 3px solid #543828;
	padding-bottom: 0.5rem;
}

section.about-us button.tabs_btn {
	color: #a89a93;
	margin-right: 1rem;
}

p.nav-tabs-contain {
	font-size: 16px;
	font-family: "GT Walsheim Pro";
	color: #363636;
}

section.about-us button.tabs_btn {
	border: 0;
	background: transparent;
	flex: 0 0 50%;
}
.about-us nav div#nav-tab {
	display: flex;
	padding-right: 0rem;
	flex-wrap: nowrap;
}
.about-us-text {
	margin-top: 110px;
}

.about-us-progressbar {
	margin-top: -243px;
	margin-left: 0px;
}

.footer-links-wrapper {
	font-size: 14px;
	font-family: "GT Walsheim Pro";
}

.About-Us-Page .about-us .img-control img {
	max-height: 100% !important;
}

.container .title {
	color: white;
	font-size: 36px;
	margin-bottom: 0;
	font-family: "GT Walsheim Pro";
	font-weight: 400;
}

.CircularProgressbar .CircularProgressbar-trail {
	stroke: #88563b !important;
	stroke-linecap: round;
	stroke-width: 1px;
}

.CircularProgressbar .CircularProgressbar-text {
	fill: #3f2d26 !important;
	font-size: 22px;
	dominant-baseline: middle;
	text-anchor: middle;
}

/* Home About End  */
