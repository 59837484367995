@media only screen and (max-width: 1300px) {
  ul.social-links1 {
    left: 3% !important;
  }
}

@media only screen and (max-width: 1230px) {
  ul.social-links1 {
    left: 1% !important;
  }
}

.home-banner {
  /* background-color: #d2d7d7; */
  /* background-image: url("../img/homeBanner.png"); */
  /* height: 694px; */
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 100vh;
}
/* .top_content{
  z-index: 9998 !important;
} */
.Home_Page h4.course-text-1 {
  color: #30231d;
  font-family: "GT Walsheim Pro";
  font-weight: 600;
}

.Home_Page p.course-text-2 {
  color: #30231d;
  font-family: "GT Walsheim Pro";
  font-weight: 600;
}

.Home_Page .home-banner .content-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: 98vh;
}

.Home_Page .home-about .btn-container {
  margin-bottom: 4rem;
  margin-top: 0;
}

.Home_Page .home-about .img-control {
  overflow: visible !important;
  height: 100%;
}

.Home_Page .text-block {
  bottom: -30px;
}

.Home_Page .course-image {
  margin: 0 !important;
}

.Home_Page .text-block {
  padding: 8px !important;
}

@media only screen and (max-width: 992px) {
  .testimonails-wrapper {
    width: 100% !important;
  }
}

/* .home-banner .content-container {
  padding-top: 100px;
  margin-bottom: 85px;
} */

.home-banner .home-sm-title {
  font-size: 18px;
  font-family: "GT Walsheim Pro";
  color: #fff;
  text-transform: uppercase;
}

.home-banner .home-bd-title {
  font-size: 66px;
  font-family: "GT Walsheim Pro";
  font-weight: bold;
  color: #fff;
  /* font-weight: 100; */
}

.home-banner .home-sm-para {
  font-size: 18px;
  font-family: "GT Walsheim Pro";
  color: #fff;
}

.home-banner .banner_btn {
  font-size: 14px;
  font-family: "Poppins-Regular";
  color: #fff;
  background-color: #543828;
  padding: 10px 40px;
}

ul.social-links1 {
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding-left: 0;
  position: absolute;
  left: 5%;
  top: 40%;
  bottom: 41%;
}

ul.social-links1 li {
  list-style: none;
}

.social-icon-color {
  color: white;
}

.about-us-image-container {
  position: absolute;
  /* bottom: 77%;
  left: 382px; */
  top: -3px;
  right: -38px;
  color: black;
  max-width: 240px;
}

.about-us-image {
  position: absolute;
  bottom: 40px;
  color: white;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
}

h4.years-of-experience {
  font-family: "GT Walsheim Pro";
  font-size: 16px;
  margin-top: 10px;
}

p.years-of-experience {
  font-size: 38px;
  font-family: "GT Walsheim Pro";
}

.about-us-image-img {
  width: 100%;
}

.text-block {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: white;
  color: black;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  border: 1px solid #fbfbfb;
  box-shadow: 0px 0px 15px #85858573;
  display: flex;
  border-radius: 3px;
}

h4.course-text {
  font-size: 16px;
  font-family: "GT Walsheim Pro";
  /* display: flex; */
  /* flex-direction: row; */
}

/* .row.about-shadow .text-block img {
  max-width: 54px;
} */

.course-text-main {
  text-align: center;
}

h4.course-text-1 {
  font-size: 12px;
  font-family: "GT Walsheim Pro";
}

p.course-text-2 {
  font-size: 39px;
  margin-bottom: 0;
}

.course-image {
  background-image: linear-gradient(90deg, #543828, #7d4f38);
  width: 80px !important;
  height: auto !important;
  margin: 14px;
  padding: 21px;
  border-radius: 6px;
}

.about-us-dialog-img {
  width: 100%;
}

p.about-us-dialog-text {
  position: absolute;
  top: 20px;
  left: -20px;
  right: 0px;
  padding: 0 24px;
  margin: 0 20px;
  width: 100% !important;
  /* display: table; */
  font-size: 12px !important;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  font-family: "GT Walsheim Pro";
  width: 464px;
  color: #704c33;
}

h2.title-second-main {
  font-family: "GT Walsheim Pro";
  font-size: 30px;
  color: #3f2d26;
}

.title-service-main {
  color: #3f2d26;
  font-size: 30px;
  font-family: "GT Walsheim Pro";
  justify-content: center;
  text-align: center;
}
.home-service .card-row .col-lg-4 {
  width: 100%;
  max-width: 100%;
}
.home-service ul.slick-dots li button:before {
  border: 2px solid #744836;
  border-radius: 50%;
  content: "";
  display: block;
  height: 10px;
  opacity: 1;
  padding: 0;
  width: 10px;
}
.home-service ul.slick-dots li.slick-active button:before {
  background: #744836;
}
.home-service .slick-next,
.home-service .slick-prev {
  background-color: #88563b;
  border-radius: 4px;
  height: 26px;
  width: 26px;
}
.home-service .slick-prev:before {
    color: #fff;
    content: "\f104";
    font-family: 'FontAwesome';
    opacity: 1;
}
.home-service .slick-next:before {
	color: #fff;
    content: "\f105";
    font-family: 'FontAwesome';
    opacity: 1;
}
.home-service .slick-next:hover, .home-service .slick-prev:hover{
	background-color: #30231d;
}
/* testimonails starts here */
.testimonails {
  padding: 80px 0px;
  background-image: url(../img/reviewText.png);
  background-repeat: no-repeat;
  background-position: top;
  margin-top: 6rem;
  background-position: center 160px;
  margin-top: 6rem;
  background-size: 78%;
}

.testimonails .review-bg {
  /* background-image: url(../img/reviewText.png); */
  background-repeat: no-repeat;
  background-size: contain;
}

.testimonails-wrapper .rating-wrapper ul {
  padding: 0px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.testimonails-wrapper .rating-wrapper ul li {
  margin: 0px 8px 0px 0px;
  color: #ffd400;
}

.testimonails-wrapper {
  background-image: linear-gradient(90deg, #543828, #7d4f38);
  padding: 18px;
  box-shadow: 0px 0px 9px 3px #bbbbbb26 !important;
  box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 16%);
  -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.16);
  margin-top: 44px;
  margin-bottom: 5px;
  min-height: 240px;
  width: 100% !important;
  margin-left: 5px;
  transform: scale(0.9);
  border-radius: 10px;
}

/* .testimonails-wrapper {
    background: #fff;
    padding: 18px;
    box-shadow: 0px 0px 10px 2px #00000017;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.16);
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.16);
  -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.16);
  margin-top: 44px;
  margin-bottom: 5px;
  width: 724px !important;
  margin-left: 5px;
  } */
.testimonails .slick-prev:before {
  content: "\f104";
  font-family: "FontAwesome";
  background: url("../img/Group 9868.png");
  padding: 29px;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 1;
  background-position: 1px 9px;
}

.testimonails .slick-prev {
  left: -10%;
  /* top: 1%; */
}

.slick-next,
.slick-prev {
  top: 59%;
}

.testimonails .slick-next:before {
  content: "\f105";
  font-family: "FontAwesome";
  background: url("../img/Group 9868.png");
  padding: 29px;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 1;
  z-index: 9;
  background-position: -1px 9px;
}

.testimonails .slick-next {
  right: -5%;
  z-index: 99;
}

img.video-image-btn {
  position: absolute;
  top: 40%;
  width: 70px;
}

/* .testimonails-slider::after {
  content: url(../img/ColorOverlay.png);
  position: absolute;
  right: 12%;
  top: 0px;
} */
.testimonails-wrapper::after {
  content: url(../img/ColorOverlay.png);
  position: absolute;
  right: 12%;
  top: -54px;
}

.testimonails-wrapper::before {
  content: url(../img/ColorOverlay.png);
  position: absolute;
  left: 14%;
  top: -54px;
  z-index: 99;
}

/* .testimonails-slider::before {
  content: url(../img/ColorOverlay.png);
  position: absolute;
  left: 14%;
  top: 0px;
  z-index: 99;
} */

.testimonails-wrapper p {
  font-family: "GT Walsheim Pro";
  color: #fff;
  font-size: 14px;
  margin-top: 22px;
  padding: 0px 68px;
}

.testimonails-wrapper h6 {
  font-family: "GT Walsheim Pro";
  color: #fff;
  font-size: 16px;
}

.testimonails h2 {
  color: #3f2d26;
  font-family: "GT Walsheim Pro";
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

p.testmonials {
  color: #88563b;
  font-size: 16px;
  font-family: "Helvetica";
}

.testimonails-wrapper img {
  margin-top: -62px !important;
  margin: 0px auto;
}

/* testimonails ends here */

/* Home About  start*/
.home-about {
  padding: 100px 0px;
}
.home-about .container{
  
  max-width: 1400px !important;
}

.home-about .tags-title {
  color: #88563b;
  font-family: "GT Walsheim Pro";
  margin: 0;
  margin-top: 100px;
}

.home-about .about-shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: #fff;
}

.home-about .title {
  font-size: 32px;
  color: #3f2d26;
  font-family: "GT Walsheim Pro";
}

.home-about .para {
  color: #a89a93;
  font-family: "GT Walsheim Pro";
  font-size: 18px;
}

.home-about .points {
  color: #30231d;
  font-family: "GT Walsheim Pro";
  font-size: 16px;
}

.home-about .home-icon {
  color: #88563b;
  margin-right: 15px;
  font-size: 20px;
}

.home-about .btn-container {
  margin-bottom: 90px;
  margin-top: 35px;
}

.home-about .img-control {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.home-about .img-control img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

section.home-about {
  content: "";
  height: 100%;
  width: 100%;
  background: url("../img/Path8710.png") no-repeat,
    url(../img/Aboutus.png) no-repeat;
  background-position: left center, 3rem -12rem;
  z-index: -10;
  object-fit: contain;
  background-size: auto, 90%;

  /* position: absolute; */
  /* display: block; */
  z-index: -10;
  padding-bottom: 0;
}

.service-name {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  color: #3f2d26d0;
  font-size: 18px;
  font-family: "GT Walsheim Pro";
  font-weight: 600;
}

/* Home About End  */

/* home service start  */
.home-service {
  background: url(../img//Services.png), url(../img/brownrec.png);
  background-repeat: no-repeat;
  background-size: 100%, 27rem 30rem;
  background-position: left 24rem, 13rem 12rem;
  padding: 4rem 0;
  padding-top: 6rem;
}

.home-service .service-card {
  margin-bottom: 60px;
  margin-top: 60px;
  border-radius: 3px;
}

.home-service .sm-tag {
  font-family: "Helvetica";
  color: #88563b;
  font-size: 18px;
  text-align: center;
}

.home-service .title {
  font-size: 32px;
  color: #3f2d26;
  text-align: center;
  font-weight: 500;
}

.home-service .service-card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px 20px;
  background: #fff;
}

.home-service .service-card .img-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.home-service .service-card .img-box img {
  width: 100%;
  height: 280px !important;
  object-fit: cover;
}

.head_home_all_same {
  font-size: 28px !important;
  font-weight: 600 !important;
}

.head_home_all_same_small {
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-bottom: 0;
}

.Home_Page .content_box {
  width: auto;
  /* margin-left: 0.5rem; */
}

.Home_Page .star-svg {
  height: 15px;
  width: 15px;
}

.Home_Page .rating-wrapper {
  margin-top: 0;
}

.Home_Page .testimonails-wrapper h6 {
  margin-bottom: 0;
}

.home-service .service-card .title {
  font-size: 18px;
  color: #3f2d26;
  font-weight: 500;
  font-family: "GT Walsheim Pro";
}

.home-service .service-card .price-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-service .service-card .price {
  color: #806c65;
  font-size: 16px;
  font-weight: 500;
  font-family: "GT Walsheim Pro";
}

.home-service .service-card a {
  color: #3f2d26;
  font-size: 16px;
  font-weight: 500;
  font-family: "GT Walsheim Pro";
}

p.service-price {
  color: #806c65;
  font-size: 16px;
  font-weight: 500;
  font-family: "GT Walsheim Pro";
}

/* home service end  */

/* client-count start  */
.home-client {
  background-size: cover;
  background: linear-gradient(90deg, #543828, #7d4f38);
  background-image: url(../img/clientbg1.png);
  padding-top: 64px;
  padding-bottom: 240px;
  /* background: linear-gradient(90deg, #543828, #7d4f38) ,url(../img/Video.png); */
  padding: 7rem 0;
  background-size: cover;
}

.home-client .tag-sm {
  color: #fff;
  text-align: center;
  font-family: Helvetica;
  font-size: 18px;
}

.home-client .title {
  font-size: 32px;
  color: #fff;
  text-align: center;
  font-family: "GT Walsheim Pro";
  margin-bottom: 80px;
}

.home-client .home_browbox_headingss {
  font-size: 13px;
  font-weight: 500;
  font-family: "Poppins-Regular";
  align-items: center;
  text-align: center;
  color: white;
  padding-top: 15px;
}

.Home_Page .max_width_icon_home {
  max-width: 40px;
}

.home-client .mile-box {
  color: #fff;
  display: flex;
  align-items: center;
  background: #8e6550;
  border: 1px solid #8e6550;
  padding: 15px 15px;
  padding-top: 0;
  margin: 0 7px;
  box-shadow: 0px 3px 3px 0px rgb(0 0 0 / 10%);
  justify-content: center;
}

.home-client .mile-box .content_box h5 {
  font-size: 13px;
  font-weight: 500;
  font-family: "Poppins-Regular";
}

.home-client .mile-box .content_box h3 {
  font-size: 39px;
  font-weight: 600;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0;
}

.home-client .mile-box .content_box h3 span.symbol {
  font-size: 23px;
  margin-left: 5px;
}

.home-client .mile-box .icon_box figure {
  margin: 0;
}

.home-client .mile-box .icon_box {
  margin-right: 15px;
}

/* Store Css Start Here */

/* Video Sec Start Here */
.video_sec {
  position: relative;
  margin-top: -13%;
}

.video_sec .ply-btn {
  position: absolute;
  bottom: -7rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  /* animation: scale 1s infinite alternate; */
}

section.video_sec .preview_img {
  transform: translate(0px, 120px);
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

/* Video Sec End Here */

/* client-count end  */
