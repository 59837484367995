.login .login-inner {
  padding: 75px 0px;
}

.login .login-inner .input-cont {
  margin-top: 18px;
  margin-bottom: 6px;
}

.login .login-inner .input-cont2 {
  margin-top: 10px;
  margin-bottom: 20px;
}

.Appoinment-Request-title {
  font-size: 30px;
  font-family: "Helvetica";
  color: #000000;
  font-weight: bold;
}

/* button.btn {
  border-radius: 0px;
  background-image: linear-gradient(to right, #543828, #7d4f38);
  color: white;
  font-weight: 400;
  font-size: 11px;
} */
.book-appoinment-btn {
  color: #fff;
  padding: 15px 49px;
  border: unset;
  margin-top: 28px;
}

.download_form {
  font-size: 18px;
}

.Modal_Box_BookAppointment {
  background-color: #fff;
  height: 100%;
}

.payment_modal .red_cross_button {
  display: inline-block;
  background: red;
  padding: 0 6px 0 7px;
  color: white;
}

.book-appoinment-container {
  /* float: left; */
  display: flex;
  justify-content: end;
}

.your-information {
  margin-bottom: 72px;
}

.login .main-para {
  font-family: "Poppins-Regular";
  font-size: 15px;
  color: #0000007d;
}

.login input {
  width: 100%;
  font-family: "Poppins-Regular";
  padding: 0.75rem 24px;
  border: 1px solid #bfbfbf;
}

input {
  font-family: "Poppins-Regular!important";
}

/* .login .label {
  font-size: 16px;
  font-family: "GT Walsheim Pro";
  color: #272727;
} */

.login .right-sec {
  padding: 278px 28px;
  text-align: center;
}

.login .register-btn {
  color: #fff;
  background-image: linear-gradient(to right, #543828, #7d4f38);
  padding: 10px 40px;
  border: unset;
}

.login .register-btn:hover {
  color: #543828;
  background-image: unset;
  background-color: transparent;
  border: 1px solid #543828;
}

.login .signin-btn {
  color: #fff;
  background-image: linear-gradient(to right, #543828, #7d4f38);
  padding: 10px 40px;
  border: unset;
  width: 100%;
  margin-top: 28px;
}

/* .login .signin-btn:hover {
  color: #543828;
  background-image: unset;
  background-color: transparent;
  border: 1px solid #543828;
} */

.login .remember-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login .field-set {
  padding-right: 75px;
}

.login .remember-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login .remember-cont input {
  width: auto;
  padding: 0;
  border: unset;
}

.login .remember-cont label {
  font-size: 13px;
  margin-left: 0px;
  font-family: "GT Walsheim Pro";
  margin-top: 10px;
}

.login a {
  font-size: 13px;
  margin-left: 6px;
  font-family: "GT Walsheim Pro";
  margin-top: 10px;
  color: #000;
}

.login a:hover {
  text-decoration: underline;
}

.login .right-title {
  font-size: 30px;
  color: #000;
  font-weight: bold;
  font-family: "Helvetica";
}

.collapse.show {
  display: block;
  background-color: #fff;
  color: none !important;
  font-size: 14px;
  font-family: "GT Walsheim Pro";
  margin-left: 17px;
  background: transparent !important;
}

/* Modal css start here */
.main-rating-modal {
  display: flex;
  justify-content: space-between;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 100%;
  /* height: 52px; */
  padding: 0.35rem 0.5rem;
  box-shadow: 0px 0px 9px #0000004a;
}

.main-rating-modal1 {
  display: flex;
  justify-content: space-between;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 100%;
  height: 52px;
}

p.rating-text-modal {
  margin-top: 15px;
  margin-left: 20px;
  font-family: "GT Walsheim Pro";
}

.rating-wrapper-modal {
  margin-top: 17px;
}

.start-ul-modal {
  list-style: none;
  display: flex;
  margin-right: 15px;
}

.btn-primary {
  color: #fff;
  background-color: unset;
  border-color: unset;
}

.billing-request.modal-title.h4 {
  margin-left: 31%;
  font-size: 28px;
  font-family: "Poppins-Regular";
  font-weight: bold;
}

h3.summary {
  font-size: 28px;
  font-family: "Poppins-Regular";
  font-weight: bold;
}

p.summary-paragraph {
  color: #000;
  font-size: 13px;
  font-weight: 400;
  font-family: "Poppins-Regular";
}

p.summary-paragraph2 {
  color: #000;
  font-size: 13px;
  font-family: "Poppins-Regular";
  font-weight: bold;
}

/* modal css end  hre */

/* second modal css start here */
.thank-you-contanair {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 0px;
}

h2.thank-you-modal {
  font-size: 28px;
  font-family: "Helvetica";
  margin-bottom: 27px;
  color: #000000;
  font-weight: bold;
}

p.thank-you-para {
  font-size: 12px;
  font-family: "GT Walsheim Pro";
  color: #000000;
}

.download-appoinment-container {
  display: flex;
  flex-direction: column;
}

button.download-appoinment.btn.btn-primary {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: unset;
  background-image: linear-gradient(to right, #543828, #7d4f38);
  color: white;
  padding: 14px 30px;
  margin-bottom: 40px;
}

button.cancel-appoinment.btn.btn-primary {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: unset;
  background-image: linear-gradient(to right, #000000, #000000);
  color: white;
  padding: 14px 30px;
  margin-bottom: 40px;
}

.form-group label {
  font-size: 12px !important;
  color: #000000 !important;
  font-family: "Poppins-Regular" !important;
  font-weight: 600 !important;
  margin-top: 13px !important;
}

p.label {
  font-size: 12px;
  color: #000000;
  font-family: "Poppins-Regular";
  font-weight: 600;
  margin-top: 13px;
}

label.inputState {
  font-size: 12px;
  color: #000000;
  font-family: "Poppins-Regular";
  font-weight: 600;
  margin-top: 13px;
}

.summary-fees-container {
  justify-content: center;
  text-align: center;
  margin-top: 0px;
}

p.summary-paragraph-fees {
  color: #000;
  font-family: "Poppins-Regular";
  /* font-weight: bold; */
}

.modal-body .Fees_heading{
  text-align: center!important;
}

.modal-body p.summary-paragraph-fees{
  text-align: left;
}

p.summary-paragraph-fees2 {
  color: #000;
  font-size: 14px;
  font-family: "Poppins-Regular";
  font-weight: bold;
}

button.confirm-booking.btn.btn-primary {
  font-size: 12px !important;
  width: 170px !important;
  height: 46px !important;
  justify-content: center !important;
  text-align: center !important;
  align-items: center !important;
  padding-top: 9px !important;
}

button.btn.book-appoinment-btn:hover {
  color: white !important;
}

/* second modal css end here */