.star-rating {
  direction: rtl;
  display: inline-block;
  /* padding: 20px; */
  cursor: default;
  margin-left: 100px;
}

.star-rating input[type="radio"] {
  display: none;
}

.star-rating label {
  color: #bbb;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.star-rating label:hover,
.star-rating label:hover ~ label,
.star-rating input[type="radio"]:checked ~ label {
  color: #f2b600;
}

button.btn.btn-submit-password-change {
  float: right;
}

p.my-card-handler {
  margin-top: 9px;
  margin-right: 48px;
  margin-left: 14px;
}

.checking {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding-left: 7px;
  margin-top: 5px;
  margin-left: 84px;
  margin-right: 10px;
}

.checkBox_clr_brown {
  filter: sepia(56%) brightness(80%) hue-rotate(170deg) saturate(70%)
    contrast(300%);
}

p.use-default {
  position: absolute;
  margin-left: -23px;
  margin-top: 34px;
  margin-right: 10px;
}

.billing-form {
  position: relative;
}

button.btn.btn-submit-password-change {
  border-radius: unset;
  background-image: linear-gradient(to right, #543828, #7d4f38);
  color: white;
  height: 50px;
  width: 180px;
  font-family: "Poppins-Regular";
  font-size: 14px;
  font-weight: 500;
}

.main-rating {
  display: flex;
  justify-content: space-between;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 60%;
}

p.rating-text {
  font-size: 12px !important;
  color: #000000 !important;
  font-family: "Poppins-Regular" !important;
  font-weight: 600 !important;
  margin-top: 13px !important;
  margin-left: 20px;
}

.main-rating .star-svg {
  font-size: 12px;
  height: 24px;
  width: 24px;
  margin-top: 5px;
}

.rating-wrapper {
  margin-top: 17px;
}

.start-ul {
  list-style: none;
  display: flex;
  margin-right: 15px;
}

.VisaCard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  max-width: 970px;
  /* padding: 85px 50px; */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  border-radius: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -4px;
  left: -1px;
  position: relative;
  background-color: white;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid red;
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -4px;
  left: -1px;
  position: relative;
  background-color: red;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

p.my-personal-card {
  font-size: 10px;
}

.col.my-personal-card-container {
  margin-top: 8px;
}

.col.my-use-default {
  margin-left: 21px;
  margin-top: 4px;
}

.VisaCard .VisaCard input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.rating-wrapper-modal {
  margin-top: 11px !important;
}

p.main-head-card-number {
  color: #000000;
  font-size: 27px;
  font-family: "GT Walsheim Pro";
  margin-bottom: 22px;
  margin-top: 50px;
  font-weight: 500;
}

.button-group-add-card {
  /* border-radius: 5px; */
  background-image: linear-gradient(to right, #fab528, #fab528) !important;
  color: #fff;
  border: unset;
  font-size: 12px;
  width: 170px;
  height: 45px;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-top: 13px;
  font-weight: bold;
}

a.add-card {
  background-image: linear-gradient(to right, #fab528, #fab528);
  color: black;
  /* border: unset; */
  height: 45px;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-top: 13px;
}

.visacard-container {
  margin-top: 30px;
}

.VisaCard .VisaCard {
  cursor: pointer;
  width: 48.5%;
}

.VisaCard .VisaCard .VisaCard-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* padding: 30px; */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid #e1e2e7;
  border-radius: 10px;
  -webkit-transition: -webkit-box-shadow 0.4s;
  transition: -webkit-box-shadow 0.4s;
  -o-transition: box-shadow 0.4s;
  transition: box-shadow 0.4s;
  transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
  position: relative;
}

.VisaCard .VisaCard .plVisaCardan-content img {
  margin-right: 30px;
  height: 72px;
}

.VisaCard .VisaCard .VisaCard-details span {
  margin-bottom: 10px;
  display: block;
  font-size: 20px;
  line-height: 24px;
}

.container .title {
  font-size: 16px;
  font-weight: 500;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  margin-bottom: 20px;
}

.VisaCard .VisaCard .VisaCard-details p {
  font-size: 14px;
  line-height: 18px;
}

.VisaCard-details {
  width: 300px !important;
}

.VisaCard .VisaCard .VisaCard-content:hover {
  -webkit-box-shadow: 0px 3px 5px 0px #e8e8e8;
  box-shadow: 0px 3px 5px 0px #e8e8e8;
}

.VisaCard .VisaCard input[type="radio"]:checked + .VisaCard-content:after {
  content: "";
  position: absolute;
  height: 8px;
  width: 8px;
  background: red;
  right: 20px;
  top: 20px;
  border-radius: 100%;
  border: 3px solid #fff;
  -webkit-box-shadow: 0px 0px 0px 2px red;
  box-shadow: 0px 0px 0px 2px red;
}

.VisaCard .VisaCard input[type="radio"]:checked + .VisaCard-content {
  border: 2px solid #e1e2e7;
  background: #eaf1fe;
  -webkit-transition: ease-in 0.3s;
  -o-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
}

@media screen and (max-width: 991px) {
  .plans {
    margin: 0 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    /* padding: 40px; */
  }

  .VisaCard .VisaCard {
    width: 100%;
  }

  .VisaCard.complete-VisaCard {
    margin-top: 20px;
  }

  .VisaCard .VisaCard .VisaCard-content .VisaCard-details {
    width: 70%;
    display: inline-block;
  }

  .VisaCard .VisaCard input[type="radio"]:checked + .VisaCard-content:after {
    top: 45%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}

@media screen and (max-width: 767px) {
  .VisaCard .VisaCard .VisaCard-content .plVisaCardan-details {
    width: 60%;
    display: inline-block;
  }
}

@media screen and (max-width: 540px) {
  .VisaCard .VisaCard .VisaCard-content img {
    margin-bottom: 20px;
    height: 56px;
    -webkit-transition: height 0.4s;
    -o-transition: height 0.4s;
    transition: height 0.4s;
  }

  .VisaCard .VisaCard input[type="radio"]:checked + .VisaCard-content:after {
    top: 20px;
    right: 10px;
  }

  .VisaCard .VisaCard .VisaCard-content .VisaCard-details {
    width: 100%;
  }

  .VisaCard .VisaCard .VisaCard-content {
    /* padding: 20px; */
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
}

/* inspiration */
.inspiration {
  font-size: 12px;
  margin-top: 50px;
  position: absolute;
  bottom: 10px;
  font-weight: 300;
}

.inspiration a {
  color: #666;
}

@media screen and (max-width: 767px) {
  /* inspiration */
  .inspiration {
    display: none;
  }
}
