.privacy-policy {
  margin-bottom: 125px;
  padding-top: 55px;
}

.privacy-policy-text {
  color: #88563b !important;
  font-family: "Poppins-Regular" !important;
  margin-top: 40px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}
.privacy-policy-text2 {
  font-size: 30;
  margin-top: 20px;
  font-family: "GT Walsheim Pro";
  font-weight: bold;
  font-size: 32px;
  color: #000000;
}

p.privacy-policy-text3 {
  font-size: 15px;
  font-family: "Montserrat";
  color: #000000;
  font-weight: 500;
}