/* home service start  */

.Blog-rates .Blog-card {
  margin-bottom: 60px;
  /* margin-top: 60px; */
}

.Blog-rates .sm-tag {
  font-family: "Helvetica";
  color: #88563b;
  font-size: 19px;
  text-align: center;
}

.inpy_checkbx_blogs {
  filter: sepia(56%) brightness(80%) hue-rotate(170deg) saturate(70%)
    contrast(300%);
  border: 1px solid rgba(125, 79, 56, 1);
  border-radius: 0px;
}

.Blog-rates .img_blog_many_main {
  max-height: 150px;
  object-fit: contain;
}

.Blog-rates .Blogs-title {
  margin-bottom: 50px;
  margin-top: 50px;
  margin-left: 30px;
}

.Blog-rates .Blog-card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px 20px;
}

.Blog-rates .Blog-card .Blogs-img-box {
  /* display: flex; */
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.BLOG_PAGE .ico_hght_wdth {
  font-size: 12px;
}

.BLOG_PAGE .View_More_Page_Open {
  width: 100%;
  text-align: right;
}

.BLOG_PAGE .socialMedia .BoxColor {
  height: 30px;
  width: 30px;
  border-radius: 2px !important;
  padding: 0;
  align-items: center;
  align-self: center;
  display: flex;
  justify-content: center;
}

.BLOG_PAGE .socialMedia {
  margin-top: 20px;
  margin-bottom: 0rem;
}

.BLOG_PAGE .Blogs_details {
  height: 100%;
  align-items: left;
  /* align-self: center; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.BLOG_PAGE .Blog-rates .img_blog_many_main {
  max-height: none;
  object-fit: contain;
  width: 100%;
  height: 300px;
}

.Blog-rates .Blog-card .service-title {
  font-size: 18px;
  color: #3f2d26;
  font-weight: 500;
  font-family: "GT Walsheim Pro";
}

.Blog-rates .Blog-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 62px;
}

.Blog-rates .Blog-card .price {
  color: #806c65;
  font-size: 16px;
  font-weight: 500;
  font-family: "GT Walsheim Pro";
}

.Blog-rates .Blog-card a {
  color: #806c65;
  font-size: 16px;
  font-weight: 500;
  font-family: "GT Walsheim Pro";
}

.blogs-book-now {
  display: flex;
  margin-top: auto;
}

.Blogs_details .timeLine {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  justify-content: normal;
  text-align: center;
  margin-bottom: 1rem;
}

.blog_text_para h4 {
  font-family: "GT Walsheim Pro";
  font-size: 15px;
}

.socialMedia {
  display: flex;
  margin-top: 31px;
}

.timeLine .calender {
  display: flex;
  margin-right: 10px;
  padding-right: 9px;
  align-items: center;
}

.timeLine .calender p {
  margin-left: 10px;
}

.timeLine .calender .calender-icon {
  margin-right: 10px;
}

.timeLine .userName {
  display: flex;
  margin-right: 10px;
  align-items: center;
}

.timeLine .comment {
  display: flex;
  align-items: center;
}

.timeLine .userName svg {
  margin: 0 20px;
  color: #88563b;
}

.timeLine .comment svg {
  margin: 0 20px;
  color: #88563b;
}

.timeLine .calender svg {
  margin-right: 10px;
  color: #88563b;
}

.timeLine .calender p {
  color: #30231d;
  font-family: "GT Walsheim Pro";
}

.timeLine .userName p {
  color: #30231d;
  font-family: "GT Walsheim Pro";
}

.timeLine .comment p {
  color: #30231d;
  font-family: "GT Walsheim Pro";
}

.Blogs-name p {
  font-family: "GT Walsheim Pro";
  color: #88563b;
}

.Blogs-name h4 {
  font-family: "GT Walsheim Pro";
  color: #3f2d26;
  font-size: 34px;
}

.blog_text_para h4 {
  color: #000;
  font-family: "GT Walsheim Pro";
}

.Blog-text-icon {
  display: flex;
  flex-wrap: wrap;
}

p.Blog-points {
  font-size: 10px;
}

.Blog-icon {
  color: #88563b;
  margin-right: 15px;
  font-size: 20px;
}

/* home service end  */

.socialMedia .BoxColor {
  background: linear-gradient(
    180deg,
    rgba(84, 56, 40, 1) 0%,
    rgba(125, 79, 56, 1) 100%
  );
  padding: 4px 10px;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 20px;
  padding-bottom: 10px;
  color: #fff;
}

.socialMedia .BoxColor svg {
  font-size: 20px;
}

.Blogs_details .timeLine p {
  margin-bottom: 0;
}

.socialMedia .BoxColor:first-child {
  font-size: 20px;
}
