/* Header css Start Here */
/* link button hover */
a:hover {
  text-decoration: none !important;
}

/* link button hover */

.main-header a#navbarDropdown {
  padding: 0px;
}

/* .main-header .container {
  max-width: 1300px;
} */

.set_icon {
  font-size: 20px !important;
}

/* Brands Page Content Css */
.showcase .content h2 {
  font-family: "HighSpeed";
}

/* Brands Page Content Css */

/* Secondary Header css Start Here */
header .secondaryHeader {
  /* background: linear-gradient(57deg, black, #00000087); */
  background-color: #296253;
  padding: 10px 0;
}

header .center_account_prof_ico {
  align-self: center !important;
  color: #f7f7f7b8 !important;
  font-size: 12px !important;
  text-transform: none !important;
}

.menu_header_dropdown_head {
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.menu_header_dropdown_head:hover {
  cursor: pointer;
}

.porf_drop_menu {
  font-size: 12px;
  min-width: 100px;
}

header .profile_image_header {
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  height: 25px;
  width: 25px;
}

header .avatr_account_profile {
  font-size: 12px;
  display: flex;
  justify-content: center;
}

/* .secondaryHeader .active {
    border-bottom: 4px solid #fff;
  } */
/* header .custom-width { */
  /* padding: 0px 7%; */
/* } */

header .menu-btn {
  font-size: 14px;
  font-family: "Poppins-Regular";
  color: #fff;
  background-color: #543828;
  padding: 10px 20px;
}

header .secondaryHeader ul.secondary-items {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0;
  padding: 0;
}

header .secondaryHeader ul.secondary-items li {
  display: inline-block;
}

header .secondaryHeader ul.secondary-items li a {
  font-family: HighSpeed;
  text-decoration: none;
  color: #000;
}

header li.dropdown:hover .dropdown-menu {
  display: block;
}

header .secondaryHeader ul.secondary-items li a i {
  color: #000;
  font-size: 25px;
}

header .secondaryHeader ul.secondary-items li a.downloads {
  opacity: 0.6;
}

header .secondaryHeader ul.secondary-items li:last-child {
  margin-right: 0;
}

/* Secondary header Css End Here */

/* Main header css Start Here */
header .main-header {
  background: transparent;
}

header .main-header .logoDv {
  /* margin-top: -30px; */
  margin: 10px;
}

.back-header {
  background-color: #242424 !important;
}

header .book-an-appoinment {
  text-decoration: none;
  color: #fff;
}

header .navbarMenu {
  display: flex;
  /* justify-content: flex-end; */
  justify-content: center !important;
}

header .navbarMenu ul.navItems {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  justify-content: center;
}

header .navbarMenu ul.navItems li.menu-items {
  display: inline-block;
  padding-right: 20px;
}

header .navbarMenu ul.navItems li.menu-items:last-child {
  padding-right: 0px;
}

header .navbarMenu ul.navItems li.menu-items.btn {
  padding-left: 0;
  padding-right: 0;
}

header .navbarMenu ul.navItems li.menu-items.btn a {
  padding: 10px 15px;
  background: #d50808;
  font-family: "Montserrat-Regular";
  border-radius: 4px;
}

header .navbarMenu ul.navItems li.menu-items a {
  font-family: "Poppins-Regular";
  color: #f7f7f7b8;
  text-decoration: none;
  padding-bottom: 5px;
  font-size: 14px;
}

header .border_bottom_line_shine {
  width: 90%;
  height: 1px;
  /* background-color: white; */
  margin: auto;
  margin-top: 0rem;
  background-image: linear-gradient(2deg,
      #ffffff0d,
      #ffffff7a,
      #ffffff7a,
      #ffffff0d);
}

header .header_signin_signup {
  color: #f7f7f7b8;
  font-size: 12px;
  align-items: center;
  align-self: center;
}

.header-full .btn {
  padding: 12px 45px !important;
}

header .header_signin_signup p {
  margin-bottom: 0;
  cursor: pointer;
  width: 50px;
}

header .navbarMenu ul.navItems li.menu-items .btn-login {
  color: #fff;
  background: #286152;
  padding: 7px 16px;
  border-radius: 20px;
}

header .navbarMenu ul.navItems li.menu-items a.active {
  /* border-bottom: 3px solid #c4000a; */
  color: #ffffffeb !important;
  font-weight: 600;
  /* font-size: 15px; */
}

/* Main Header Css End here */
/* mobile header start here */
/* header .mobile-header {
  width: 30%;
  height: 100%;
  position: fixed;
  background: #000;
  top: 0;
  z-index: 1;
  right: 0;
  padding-top: 30px;
  transition: 0.7s;
  transform: translateX(100%);
  overflow-y: scroll;
} */

/* header .mobile-header.show {
  transform: translateX(0%);
} */

/* header .mobile-header ul.mobile-nav {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
} */

/* header .mobile-header .cancel {
  background: #fff;
  width: 35px;
  height: 35px;
  line-height: 55px;
  margin: 0px 20px 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* header .mobile-header ul.mobile-nav li.nav-item {
  padding: 10px 0;
  border: 0;
  border-bottom: 1px solid #dddddd4a;
} */

/* header .mobile-header ul.mobile-nav li.nav-item a {
  color: #fff;
  font-size: 14px;
  padding: 0 20px;
} */

/* header .mobile-header .secondary-items {
  list-style-type: none;
  padding: 0px !important;
} */

/* header .mobile-header .secondary-items li {
  padding: 5px 22px !important;
  line-height: 33px;
  border-bottom: 1px solid;
  width: 100%;
} */

/* .mobile-header ul li a {
  font-family: "Montserrat-Regular";
  font-size: 17px !important;
  color: #fff !important;
} */

/* mobile header content */

/* small header starts*/

.header-small .ico_pos-rel_headd {
  position: relative;
  top: -4px;
}

.btn-swip-hhdr {
  background-color: #00c11a !important;
  color: white;
  margin-right: 0.5rem;
  margin-top: 0rem;
}

.header-small {
  display: none;
}

.swip_header_sml {
  display: none;
}

.header .align-center {
  align-self: center;
}

.form-control {
  color: #000 !important;
}

.Billing_Details_Page p.my-card-handler {
  font-family: "GT Walsheim Pro";
}

.Billing_Details_Page p.use-default {
  font-family: "GT Walsheim Pro";
}

@media only screen and (max-width: 425px) {
  li.my-appoinment-nav-item {
    max-width: 100% !important;
    place-items: center;
    align-self: center;
    /* padding-right: 1vw!important; */
  }
}

@media only screen and (max-width: 480px) {
  .PROFILE_PAGE .profile .info-profile {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  .Billing_Details_Page p.my-card-handler {
    margin-right: 30px !important;
  }

  .PROFILE_PAGE h3.upload-text1 {
    font-size: 16px !important;
  }

  ul.nav.nav-tabs {
    margin-left: 0px !important;
    margin-bottom: 35px !important;
    border-bottom: none !important;
    flex-direction: column !important;
    min-height: 150px !important;
    justify-content: space-between !important;

  }

  input::placeholder {
    font-size: 13px !important;
  }

  .newsletter-input-wrapper {
    flex-direction: column;
  }

  .Content_detail-page .col-md-6 {
    max-width: 100% !important;
  }

  .product_detail_page .firstReviewCard {
    flex-direction: column;
  }

  .newsletter-input-wrapper {
    background-color: #fff0 !important;
  }

  .newsletter .bt-susbcribe {
    max-width: 300px;
    margin-right: auto;
    margin-top: 1rem;
  }

  .Home_Page .content_box {
    width: auto !important;
  }

  .Home_Page .home-client .mile-box {
    justify-content: center !important;
  }

  .header-inner .bg-pink-header-buy {
    width: 100% !important;
    padding: 1rem;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link {
    min-width: 150px !important;
    padding: 0.5rem 1.25rem !important;
  }
}

@media only screen and (max-width: 992px) {
  .CHANGE_PASSWORD_PAGE .res_center_text_edit_password {
    text-align: center;
  }

  .CHANGE_PASSWORD_PAGE .visiblity_icon_posb {
    position: absolute;
    top: 50%;
    right: 10%;
    cursor: pointer;
    font-size: 16px;
    color: #707070;
  }

  .CHANGE_PASSWORD_PAGE .security-form-wrapper button.btn-submit-password-change {
    width: 100%;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link {
    width: 150px !important;
    min-width: 150px !important;
    padding: 0.5rem 1.25rem !important;
  }

  .footer .res-px-0 {
    padding-left: 15px !important;
  }

  .footer .footer-res-amrgin {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-left: 2.75rem;
  }

  .testimonails-wrapper::after {
    right: 5% !important;
    transform: scale(0.35) !important;
  }

  .testimonails-wrapper::before {
    left: 5% !important;
    transform: scale(0.35) !important;
  }

  h3.subscribe-Newsletter-heading {
    font-size: 18px;
  }

  .footer-widget-4 {
    padding-left: 1.85rem !important;
  }

  .My_Post_Review .main-rating {
    width: 100%;
    font-size: 15px !important;
  }

  .resposive_full_width_header {
    width: 100%;
    padding: 0 !important;
  }

  .My_Post_Review .star-svg {
    height: 30px;
    margin-top: 3px;
    width: 30px;
  }

  .footer .brdr_right_white {
    display: none;
  }

  .footer .quick_link {
    text-align: left !important;
  }

  .profile .info-profile {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .profile .user-upload {
    flex-direction: column !important;
  }

  .About-Us-Page .about_bottom_sec_head {
    margin-top: 2rem;
  }

  .nav-tabs .nav-link {
    min-width: 230px;
  }

  .service-name-service {
    margin-top: 1rem;
  }

  .profile .main-head {
    margin-top: 1rem !important;
  }

  .profile .profile-row {
    padding-top: 0px !important;
  }

  .profile .security-form-wrapper button.btn-submit-password-change {
    margin-top: 1rem !important;
  }

  .security-form-wrapper input {
    margin-top: 1rem !important;
  }

  .home-about .img-control {
    position: relative;
  }

  .text-block {
    left: 0px !important;
    bottom: 0px !important;
  }

  .newsletter {
    margin-right: 0px !important;
  }

  .footer-1-content-wrapper {
    border-right: unset !important;
  }

  .footer-widget-2 {
    border-right: unset !important;
  }

  .footer-widget-3 {
    border-right: unset !important;
  }

  .header .header-top-pink .justify-space-hdr-top {
    border-bottom: 1px solid #ffffff63;
  }

  .footer {
    background-color: #63402b !important;
  }

  .bg-red-res-hdr {
    background-color: #fff;
    color: black;
    margin-top: 2px;
    margin-right: 6px;
  }

  .copy-right-wrapper h6 {
    text-align: center !important;
  }

  .copy-right-wrapper p {
    text-align: center !important;
  }

  .about-us-progressbar {
    margin-top: 30px !important;
  }

  .main-header .container {
    padding-left: 0px;
    padding-right: 0px;
    width: 100% !important;
    max-width: 100% !important;
  }

  .profile_img_header {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    object-fit: cover;
  }

  .header-full {
    display: none;
  }

  .swip_header_sml {
    display: block;
  }

  .header-small {
    display: block;
    align-self: center;
  }

  .header .flx-header-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .header-small .img_respon {
    /* height: 40px; */
    padding: 5px 5px !important;
    background-color: #553623 !important;
    margin-right: 1rem;
    width: 115px;
    object-fit: cover;
  }

  .header-small .line-small-hdr {
    margin-left: 10px;
  }

  .header-small .bg-red-hdr {
    background-color: #553623;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.5rem;
  }

  .header-small .align-center {
    align-self: center;
  }

  .clr-hom-sml-hdr {
    color: #553623;
    text-decoration: none;
  }

  .clr-hom-sml-hdr :hover {
    color: #202076 !important;
    text-decoration: none;
    /* border-bottom: 1px solid #df1921; */
    /* padding-bottom: 0.5rem; */
  }

  .list-style-type-circle {
    list-style-type: circle;
  }

  .flx-1 {
    flex: 1;
  }

  .flx-2 {
    flex: 4.5;
  }

  .flex-end-hdr-smll {
    justify-content: flex-end;
    cursor: pointer;
    color: #df1921;
  }

  .flex-end-hdr-smll:hover {
    color: #202076;
  }

  .close_btn_sml_hdr {
    float: right;
    margin-right: 1rem;
    position: relative;
    top: -10%;
    right: -30%;
  }

  .MuiDrawer-root>.MuiPaper-root {
    border-radius: 0px 0px 8px 8px;
  }

  .swip_header_sml .css-1ytz6dp {
    background-color: #553623;
  }

  .pos-rel-top-four {
    color: #553623 !important;
    position: relative;
    top: 3px;
    left: 12px;
    font-size: 12px;
  }

  .content_control_swiphead_res_header {
    /* width: 100vw; */
    height: 100%;
    padding: 10px 0px;
    padding-right: 20px;
  }

  .social-links1 {
    display: none !important;
  }

  .social_links_swipheader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .social_links_swipheader i {
    font-size: 20px;
    z-index: 1;
    color: #543828;
    cursor: pointer;
    text-decoration: none;
    list-style-type: none;
    margin-right: 0.5rem;
    margin-top: 1rem;
  }

  .home-bd-title {
    font-size: 32px !important;
  }

  .home-sm-title {
    font-size: 17px !important;
  }

  .home-sm-para {
    font-size: 14px !important;
  }

  .about-us-image-container {
    /* left: 0 !important; */
    position: absolute;
    top: 0px;
    right: -18px !important;
    color: black;
    /* max-width: 240px; */
    width: 180px;
  }

  h4.years-of-experience {
    font-size: 13px !important;
    margin-top: 0 !important;
  }

  p.years-of-experience {
    font-size: 16px !important;
    margin-bottom: 0;
  }

  /* .about-us-image-container { */
    /* display: none; */
  /* } */

  .home-about {
    padding: 20px 0 !important;
  }

  .home-about .tags-title {
    margin-top: 10px !important;
  }

  p.about-us-dialog-text {
    position: static !important;
    width: auto !important;
    padding: 0px !important;
    margin: 0px !important;
    margin-bottom: 1rem !important;
  }

  .home-banner .content-container {
    padding-top: 30px !important;
  }

  .common-banner {
    height: 230px !important;
  }

  .faq {
    padding: 40px 0 !important;
  }

  .LOGIN_PAGE .login-inner {
    margin-top: 20px;
  }

  .img_dsp_nun {
    display: none;
  }

  .home-service {
    padding: 1rem 0 !important;
  }

  .home-service .service-card {
    margin: 10px 0 !important;
  }

  .home-client {
    background-color: #543828 !important;
    padding: 3rem 0 !important;
  }

  .testimonails-slider::after {
    display: none !important;
  }

  .testimonails-slider::before {
    display: none !important;
  }

  .testimonails-wrapper {
    width: 100% !important;
  }

  .testimonails .slick-prev::before {
    display: none !important;
  }

  .testimonails .slick-next:before {
    display: none !important;
  }

  /* .about-us-progressbar {
    margin-top: -50px !important;
  } */

  .border-color {
    padding: 22px !important;
  }

  p.service-points-service:nth-child(even) {
    margin-left: 0rem !important;
  }

  .service-rates .service-card .service-img-box {
    flex-direction: column !important;
  }

  .service-rates .service-card .service-img-box img {
    width: 100% !important;
    height: auto !important;
  }

  .service-image {
    width: 100% !important;
    height: auto !important;
    margin-right: 0px !important;
  }

  .sevice_details-service {
    width: 100% !important;
  }

  .indvisual-text {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .Counselling-indvisual {
    padding-top: 20px !important;
  }

  .family-Counselling-text {
    margin-bottom: 20px !important;
  }

  .container .title {
    font-size: 19px !important;
  }

  .modal-body .summary-fees-container {
    text-align: left !important;
  }

  .modal-body .h3.summary {
    font-size: 16px;
    font-family: "Poppins-Regular";
    font-weight: bold;
  }

  .billing-request.modal-title.h4 {
    margin-left: 0 !important;
  }

  .About-Us-Page .nav-tabs {
    padding-right: 0 !important;
    width: 100%;
  }

  .testimonails-wrapper::after {
    transform: scale(0.5);
  }

  .testimonails-wrapper::before {
    transform: scale(0.5);
  }

  .video-section {
    margin-top: 30px !important;
  }

  .contact_sec {
    padding: 20px 0 60px !important;
  }

  .heading {
    padding-top: 0px !important;
  }

  .contact_sec form {
    padding-top: 20px !important;
  }

  .Blogs-name h4 {
    font-size: 20px !important;
  }

  .Blogs_details .timeLine {
    flex-direction: column !important;
    justify-content: flex-start !important;
  }

  .timeLine .calender {
    align-items: flex-start !important;
    width: 100%;
    margin-right: 0 !important;
  }

  .timeLine .userName {
    margin-right: 0px !important;
  }

  .timeLine .userName {
    align-items: flex-start !important;
    width: 100%;
    margin-right: 0;
  }

  .timeLine .comment {
    align-items: flex-start !important;
    width: 100%;
    margin-right: 0;
  }

  .timeLine .comment svg {
    margin-left: 0px !important;
  }

  .timeLine .userName svg {
    margin-left: 0px !important;
  }

  .pro-details .timeLine {
    flex-direction: column;
  }

  .timeLine span {
    display: none;
  }

  .imagedivbottom {
    flex-direction: column !important;
  }

  .commentSection {
    width: 100% !important;
  }

  .blankCheckbox {
    font-size: 12px;
  }

  .conditionrow {
    width: 100% !important;
  }

  .privacy-policy {
    padding-top: 10px !important;
  }

  .login .field-set {
    padding-right: 0px !important;
    margin-bottom: 1rem;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    font-size: 11px;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link {
    font-size: 11px;
  }

  .ul.nav.nav-tabs {
    margin-left: 0px !important;
  }

  li.my-appoinment-nav-item {
    max-width: auto !important;
    margin-left: 10px !important;
    margin-right: 0 !important;
  }

  .login .right-sec {
    padding: 20px 0px !important;
  }

  .login .login-inner {
    padding: 00px !important;
  }

  .Appoinment-Request-title {
    padding-top: 20px;
  }



  .profile .password-btn {
    text-align: center;
  }

  .password-btn a.change-password-btn {
    margin-left: 0px !important;
    position: static !important;
  }

  p.login-title {
    font-size: 32px !important;
  }

  .login .right-title {
    font-size: 26px !important;
  }

  p.forget-main-para {
    font-size: 14px !important;
  }

  .forgert-input-cont {
    margin-left: 0 !important;
  }

  img.forget-image {
    display: none;
  }

  .login .verify-btn {
    width: auto !important;
    margin-top: 14px !important;
  }

  .card {
    margin-top: 30px !important;
  }

  .reset-password-container {
    margin-left: 0 !important;
    margin-top: 10px !important;
  }

  /* .common-banner .url-container {
    padding: 46px 30px !important;
  } */
}

/* mobile header content ends */

/* .canvas-icon.text-black {
  display: none;
} */

/* .navbarMenu .canvas-icon i.fa.fa-bars {
  font-size: 27px;
}

.canvas-icon.text-black {
  display: none;
} */

/* .navbarMenu .canvas-icon i.fa.fa-bars {
  font-size: 27px;
  color: #fff;
} */

.button-group {
  /* border-radius: 5px; */
  background-image: linear-gradient(to right, #543828, #7d4f38);
  color: #fff;
  border: unset;
  font-size: 12px;
  width: 170px;
  height: 45px;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-top: 13px;
}

/* .book-an-appoinment { */
  /* border-radius: 5px;
    background-image: linear-gradient(to right, #543828, #7D4F38);
    color: #fff;
    border: unset;
	font-size: 12px; */
/* } */

header .navbarMenu ul.navItems li.menu-items a {
  font-size: 11px;
}

header .flex_container {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
}

header .flex_container .Logo_Part {
  flex: 0.75;
}

header .flex_container .All_List_Part {
  flex: 3;
}

header .flex_container .bookAppointment_Part {
  flex: 0.5;
  display: flex;
  justify-content: space-between;
  width: 100%;
  width: 100%;
}

header .mr-set_prof {
  margin-right: 1rem;
}

@media only screen and (max-width: 1200px) {
  header .mr-set_prof {
    margin-right: 0.5rem;
    font-size: 10px !important;
  }
}

/* mobile header end here */
/* header responsive queries start here*/
/* @media (max-width: 1440px) {
    header .custom-width {
      padding: 0px 5%;

    }
  }

  @media (max-width: 1366px) {
    header .custom-width {
      padding: 0px 3%;

    }
  } */

@media (max-width: 1280px) {
  header .navbarMenu ul.navItems li.menu-items a {
    font-size: 10px;
  }

  header .navbarMenu ul.navItems li.menu-items {
    padding-right: 18px;
  }
}

@media (max-width: 1150px) {
  header .navbarMenu {
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 1198px) {
  .canvas-icon.text-black {
    display: block;
  }

  .secondaryHeader {
    display: none;
  }

  header .main-header .logoDv {
    margin: 0px !important;
  }
}

/* @media (max-width: 1198px) {
  header ul.navItems {
    display: none;
  }
} */

@media (max-width: 764px) {
  span.chat-count {
    margin-left: 7px;
  }
}

@media (max-width: 576px) {
  header .mobile-header {
    width: 50%;
  }
}

/* header responsive queries start here*/
/* Header css End here */