.Counselling-indvisual {
	/* background-image: linear-gradient(90deg, #fffcfc, #fff9f9); */
	padding-top: 125px;
	padding-bottom: 55px;
}

.indvisual-text {
	justify-content: center;
	margin-top: 147px;
	margin-bottom: 120px;
}

.Counselling_Page .indvisual-text {
	justify-content: center;
	margin-top: auto;
	margin-bottom: auto;
	justify-content: center;
	/* margin-top: 147px; */
	/* margin-bottom: 120px; */
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.Counselling_Page .line-couple-relationship {
	display: none;
}

.group-text-container {
	margin-top: 70px;
	margin-bottom: 120px;
}

.couple-relationship {
	background: #f7f7f7;
	padding-top: 55px;
	margin-bottom: 55px;
	padding-bottom: 55px;
	/* margin-bottom: 100px; */
}

.group-counselling {
	background: #f7f7f7;

	padding-top: 55px;
	padding-bottom: 55px;

	/* margin-bottom: 100px; */
}

h2.indvisual-main-text {
	color: #3f2d26;
	margin-bottom: 33px;
	font-size: 30px;
	font-family: "GT Walsheim Pro";
}

p.indvisual-paragraph {
	color: #000000;
	font-size: 14px;
	font-family: "GT Walsheim Pro";
}

h2.Relationship-main-text {
	color: #3f2d26;
	margin-bottom: 33px;
	font-size: 30px;
	font-family: "GT Walsheim Pro";
}

p.Relationship-paragraph {
	color: #000000;
	font-size: 14px;
	font-family: "GT Walsheim Pro";
}

h2.Group-main-text {
	color: #3f2d26;
	margin-bottom: 33px;
	font-size: 30px;
	font-family: "GT Walsheim Pro";
}

p.Group-paragraph {
	color: #000000;
	font-size: 14px;
	font-family: "GT Walsheim Pro";
}

h2.family-main-text {
	color: #e5dfdc;
	margin-bottom: 22px;
	font-size: 30px;
	font-family: "GT Walsheim Pro";
	font-weight: 36;
}

p.family-paragraph {
	color: #e5dfdc;
	font-size: 14px;
	font-family: "GT Walsheim Pro";
}

h2.life-main-text {
	color: #3f2d26;
	margin-bottom: 33px;
	font-size: 30px;
	font-family: "GT Walsheim Pro";
}

p.life-paragraph {
	color: #000000;
	font-size: 14px;
	font-family: "GT Walsheim Pro";
}

h2.Dating-main-text {
	color: #3f2d26;
	margin-bottom: 33px;
	font-size: 30px;
	font-family: "GT Walsheim Pro";
}

p.Dating-paragraph {
	color: #000000;
	font-size: 14px;
	font-family: "GT Walsheim Pro";
}

img.image-fluid {
	/* height: 69% !important; */
	width: 100%;
}

.life-counselling {
	background: #f7f7f7;

	padding-top: 50px;
	padding-bottom: 55px;
}

section.Dating-indvisual {
	background-image: linear-gradient(90deg, #fffcfc, #fff9f9);
	/* margin-bottom: 100px; */
	margin-top: 50px;
	padding-bottom: 55px;
}

.line-couple-relationship {
	height: 1px;
	color: #dfdfdf;
	border: 1px solid;
}

section.service-rates {
	margin-top: 80px;
	/* margin-bottom: 50px; */
}

h2.service-title-service {
	color: #3f2d26;
	font-size: 30px;
	font-family: "GT Walsheim Pro";
	font-weight: 600;
	/* justify-content: center; */
	/* text-align: center; */
	margin-left: 0px;
	margin-bottom: 24px;
}

.family-Counselling {
	background-image: linear-gradient(90deg, #543828, #7d4f38);
}

.family-Counselling-bg {
	background: url("../img/Rectangle 4990.png");
}

.family-Counselling {
	/* margin-bottom: -125px; */
	padding-top: 55px;
}

.family-Counselling-text {
	color: white;
	justify-content: center;
	margin-top: 84px;
	margin-bottom: 120px;
}
