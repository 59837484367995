body {
	overflow-x: hidden;
}

.APP_JS_BC {
	overflow-x: hidden;
}

.pos-rel {
	position: relative !important;
}

@font-face {
	font-family: "Signerica_Fat";
	font-style: normal;
	font-weight: normal;
	src: local("Signerica_Fat"), url("../fonts/Signerica_Fat.ttf") format("woff");
}
header {
	z-index: 999;
	position: relative;
}
.common-banner {
	/* background-image: url("../img/commonBanner.png"); */
	/* height: 34vh; */
	width: 100%;
	/* height: 284px !important; */
	background-size: cover;
	background-repeat: no-repeat;
}
.bgBanner {
	background-image: url("../img/commonBanner.png");
	/* height: 34vh; */
	width: 100%;
	/* height: 284px !important; */
	background-size: cover;
	background-repeat: no-repeat;
}
.common-banner .BackgroundVideo.video {
	height: 330px !important;
	width: 100% !important;
	object-fit: cover;
}
.common-banner .BackgroundVideo.video video {
	height: 330px !important;
	width: 100% !important;
	object-fit: cover;
	z-index: -999;
	left: 0 !important;
	right: 0 !important;
	top: 0 !important;
}

.crd-lght-plchldr::placeholder {
	color: #717171 !important;
	border-color: #7b7676;
	font-weight: 500 !important;
	font-size: 12px !important;
}

.common-banner .url-container {
	padding: 5rem 0px;
	padding-left: 0px !important;
	color: #fff;
	z-index: 999;
	position: relative;
}

.color_dull_disalbled {
	color: rgba(0, 0, 0, 0.281);
}

.red_box_delete:hover {
	color: rgba(255, 0, 0, 0.746);
	cursor: pointer;
}

.video_thumbnails {
	height: 200px;
	width: 100%;
	object-fit: cover;
}

.img_slider_testimonialsz {
	height: 100px;
	width: 100px;
	object-fit: cover;
	border-radius: 50%;
}

.btn {
	border-radius: 0px;
	background-image: linear-gradient(to right, #543828, #7d4f38);
	color: white;
	font-weight: 400;
	font-size: 11px;
	padding: 12px 30px !important;
	font-family: "GT Walsheim Pro" !important;
}

.btn-trans {
	background: transparent !important;
	border: 1.5px solid #543828;
	color: #543828 !important;
	font-family: "GT Walsheim Pro";
}

.faqs .accordianSummary {
	background: #88563b !important;
	color: white;
	border-radius: 5px 5px 0 0;
}

.faqs .accordianSummary p {
	margin-bottom: 0;
	font-weight: 600;
}

.faqs .accordian {
	box-shadow: unset;
	/* background-color: #fff9f3; */
	border-radius: 15px 15px 0 0 !important;
}

.faqs .accordionDetails {
	padding: 30px 30px;
	font-size: 14px;
	color: #7b7676;
	line-height: 22px;
	max-width: 90%;
	margin: 0 auto;
	box-shadow: 0 0 10px 0px #0000003b;
	background: #fff9f3;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input {
	font-family: "Poppins-Regular" !important;
}

select {
	font-family: "Poppins-Regular" !important;
	color: black !important;
}

.label_same_remeberme {
	font-size: 12px !important;
	font-weight: 500 !important;
	font-family: "GT Walsheim Pro";
}

.Billing_Details_Page .px-2 label {
	margin: 1rem 0;
}

.brown-Btn {
	background-color: #543828;
	color: #fff;
	font: 14px;
	font-family: "Poppins-Regular";
	padding: 8px 40px;
	margin-right: 40px;
}

.font-pop {
	font-family: "Poppins-Regular" !important;
}

.add_card_new_details {
	position: relative;
	top: -4px;
}

.font-pop::placeholder {
	color: #717171;
	margin-top: 1rem;
	padding-top: 0.35rem;
	font-family: "Poppins-Regular" !important;
	font-weight: 500 !important;
	font-size: 12px !important;
}

.BOOK_PAGE select {
	background-image: url("../Icon\ ionic-ios-arrow-down.png");
	background-position: 95% center;
	background-repeat: no-repeat;
}

.bt-add-card {
	padding: 0.5rem;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	/* position: relative;
  top: 12px; */
}

.obj_fit_cover {
	object-fit: cover;
}

.justify-space-between {
	justify-content: space-evenly;
	width: 100%;
}

.cursor_pointer {
	cursor: pointer;
}

.brown-Btn:hover {
	background-color: #fff;
	color: #543828;
	border-color: #543828;
}

.white-Border-Btn {
	background-color: #fff;
	border-color: #543828;
	color: #543828;
	font: 14px;
	font-family: "Poppins-Regular";
	padding: 8px 40px;
	margin-right: 40px;
}

.white-Border-Btn:hover {
	background-color: #543828;
	border-color: #543828;
	color: #fff;
}

.bt-pagination-all {
	padding: 0.5rem 1rem !important;
}

.bt-wdht-fixed {
	width: 180px;
}

.LOGIN_PAGE .login .main-para {
	font-size: 13px;
	margin-bottom: 2.5rem;
}

.LOGIN_PAGE .right-sec p {
	font-size: 13px;
	font-family: "Poppins-Regular";
}

.LOGIN_PAGE input[type="checkbox"] {
	filter: sepia(56%) brightness(80%) hue-rotate(170deg) saturate(70%)
		contrast(300%);
}

p.forget-main-para {
	font-size: 13px !important;
	font-family: "Poppins-Regular";
}

.Edit_Profile .user-upload .left-side .img-cont img {
	height: 70px !important;
	width: 70px !important;
	object-fit: cover !important;
	margin: auto !important;
}

.BOOK_PAGE input {
	width: 100%;
	padding: 0.75rem 24px;
	border: 1px solid #bfbfbf;
	font-family: "Poppins-Regular";
	font-size: 13px;
}

.BOOK_PAGE select {
	height: 3rem !important;
	font-family: "Poppins-Regular";
	font-size: 13px;
}

.BOOK_PAGE .select_down_arrow_adjustable {
	position: absolute;
	right: 5%;
	top: 52.5%;
	font-weight: 400;
}
