.bg-header {
  background: #fff;
}
.bg-header ul.navbar-nav li a {
  color: #000;
}
.faq {
  padding: 80px 0px;
  /* background-image: url("../img/bgDesign.png"); */
  background-size: 100% 100%;
  background-attachment: fixed;
  background-size: 100%;
}
.faq .sm-tag {
  font-size: 18px;
  text-align: center;
  font-family: "Helvetica";
  color: #88563b;
}
.faq-wrapper h2 {
  color: #fff ;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 4px;
  font-family: "GT Walsheim Pro";
}

.faq-wrapper .main-para {
    font-size: 16px;
    color: #7B7676;
    font-family: 'GT Walsheim Pro';
    text-align: center;
}

div#accordion {
  margin-top: 30px;
  border: 1px solid #dcdcdc;
  padding: 24px 0px;
  padding-bottom: 0px;
}
div#accordion .card-header {
  background: transparent;
  border: none;
  padding: 17px 24px;
}
div#accordion .card {
  border-top: 1px solid #dcdcdc !important;
  border-radius: 0px;
  border: none;
}
div#accordion a.card-link {
  color: #000;
  font-family: "AvegasRoyale-bold";
  font-size: 18px;
}
.faq-wrapper .card-body {
  padding: 20px;
  font-family: "Montserrat";
  font-size: 14px;
  color: #afafaf;
  background-color: #FFF9F3;
}
a.card-link[aria-expanded="true"] .fa-angle-down::before {
  content: "\f068";
}
div#accordion a.card-link:active,
div#accordion a.card-link:focus {
  text-decoration: none;
}
h2.frequently-ask-questions {
  color: #3F2D26 !important;
  font-family: 'GT Walsheim Pro' !important;
  font-size: 30px;
}

.accordion-button {
  color: #fff;
  background-color: #88563b !important;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  margin-left: 0px;
}
.collapse.show {
  display: block;
  background-color: #f1efde !important;
  color: none !important;
  font-size: 14px;
  font-family: "GT Walsheim Pro";
  margin-left: 17px;
}
.accordion-item {
  background-color: #fff;
  border: 1px solid #c9c9c9;
}
.accordion-button:focus {
  z-index: 3;
  border-color: unset;
  outline: 0;
  box-shadow: 0 0 0 0.25remrgba (13, 110, 253, 0.25);
}
.accordion-button:not(.collapsed) {
  color: white;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
/* .accordion-button {
	position: relative;
	color: #54544e;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 1rem 1.25rem;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	background-color: #f1efde;
	border: 0;
	border-radius: 0;
	overflow-anchor: none;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
		border-radius 0.15s ease;
} */
.accordion-button {
  position: relative;
  color: #54544e;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: white !important;
  text-align: left;
  background-color: #f1efde;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}
button.accordion-button.collapsed {
  font-size: 16px;
  font-family: "GT Walsheim Pro";
  font-weight: 500;
}
p.faqs-paragraph {
  color: #7b7676 !important;
  font-family: "GT Walsheim Pro";
  font-size: 13px;
}
/* responsive starts here */
@media (max-width: 1100px) {
  .faq-wrapper h2 {
    font-size: 30px;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .faq {
    background: none;
  }
}
@media (max-width: 480px) {
  div#accordion a.card-link {
    font-size: 16px;
  }
}
@media (max-width: 414px) {
  div#accordion a.card-link {
    font-size: 13px;
  }
}

.accordion-button::after {
  /* background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e"); */
  transform: scale(0.7) !important;
  content: "\f067";
  font-family: "fontAwesome";
  background-image: none;
}
.accordion-button:not(.collapsed)::after {
  /* background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e"); */
  background: none;
  content: "\f068";
  font-family: "fontAwesome";
}
