.ply-btn {
  position: absolute;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
}

.Video_Page .main_full_video {
  margin: 2rem 0;
  height: 550px;
  width: 100%;
  background-color: black;
  object-fit: cover;
}

.video-section {
  margin-top: 147px;
  margin-bottom: 120px;
}

.play_btn_videos {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: 50px;
  cursor: pointer;
}

.name_caption_video {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  left: 0px;
  right: 0;
  bottom: 30px;
  color: white;
  font-size: 18px;
  text-shadow: 2px 2px 20px black;
  width: 220px;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bg_big_video {
  top: 40px;
  left: 40px;
  text-align: left;
  margin-left: 0;
  max-width: 100%;
}

.Video_Page .video-section {
  margin-top: 6rem;
}

/* .Video_Page .main_full_video{
  max-height: 600px;
  width: 100%!important;
} */
