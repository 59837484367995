.blog-detail-image img {
  width: 100%;
  max-height: 900px;
  margin-top: 60px;
  object-fit: contain;
}

.Blog-icon {
  color: #88563b;
  margin-right: 15px;
  font-size: 20px;
}

.pro-details .timeLine {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  justify-content: normal;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 15px;
}

.cpmment_image_roundround {
  width: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.firstReview-Content-text {
  width: 100%;
}

.socialMedia {
  display: flex;
  margin-top: 31px;
}

.timeLine p {
  margin-bottom: 0;
}

.timeLine .calender {
  display: flex;
  margin-right: 10px;
  padding-right: 9px;
  align-items: center;
}

.timeLine .calender p {
  margin-left: 10px;
}

.timeLine .calender .calender-icon {
  margin-right: 10px;
}

.timeLine .userName {
  display: flex;
  margin-right: 10px;
  align-items: center;
}

.timeLine .comment {
  display: flex;
  align-items: center;
}

.timeLine .userName svg {
  margin: 0 20px;
  color: #88563b;
}

.timeLine .comment svg {
  margin: 0 20px;
  color: #88563b;
}

.timeLine .calender svg {
  margin-right: 10px;
  color: #88563b;
}

.timeLine .calender p {
  color: #30231d;
  font-family: "GT Walsheim Pro";
}

.timeLine .userName p {
  color: #30231d;
  font-family: "GT Walsheim Pro";
}

.timeLine .comment p {
  color: #30231d;
  font-family: "GT Walsheim Pro";
}

.Blogs-name p {
  font-family: "GT Walsheim Pro";
  color: #88563b;
}

.Blogs-name h4 {
  font-family: "GT Walsheim Pro";
  color: #3f2d26;
  font-size: 34px;
}

.commentSection {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 40px;
}

.sc_ex {
  width: 100%;
}

section.product_detail_page .form-group input {
  background-color: transparent;
  border: 1px solid #dedede;
  font-family: "Poppins-Regular";
}

section.product_detail_page .form-group input::placeholder {
  color: #717171;
  font-family: "Poppins-Regular";
  font-weight: 500 !important;
  font-size: 12px !important;
}

.mt-blog_detail_text_last {
  margin-top: 2px;
}

.firstReviewCard {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  margin: 30px 0;
}

.content-first-section p {
  color: #88563b;
  font-family: "GT Walsheim Pro";
  margin-bottom: 0;
}

.content-first-section h4 {
  color: #3f2d26;
  font-family: "GT Walsheim Pro";
  font-size: 34px;
}

.content-second-section p {
  color: #3f2d26ce;
  font-family: "GT Walsheim Pro";
  margin-top: 25px;
}

.lusetaall ul {
  color: #88563b;
}

.lusetaall ul li p {
  color: #000;
  font-family: "GT Walsheim Pro";
}

.content-third-section p {
  color: rgba(0, 0, 0, 0.747);
  font-family: "GT Walsheim Pro";
  margin-bottom: 42px;
}

.details-content-section2 h3 {
  color: #000000c0;
  font-family: "GT Walsheim Pro";
  font-weight: bold;
}

.details-content-section2 p {
  color: #000000c0;
  font-family: "GT Walsheim Pro";
  font-size: 14px;
}

.reviewDetails h3 {
  color: #000000c0;
  font-family: "GT Walsheim Pro";
  font-weight: bold;
}

.firstReview-Content-text .commentUsername {
  color: #274d5e;
  font-family: "GT Walsheim Pro";
}

.commentDate {
  color: #818181;
  font-family: "Poppins-Regular";
}

.usercommentsend {
  color: rgba(0, 0, 0, 0.767);
  font-family: "GT Walsheim Pro";
}

.replytext {
  color: #274d5e;
  font-family: "GT Walsheim Pro";
}

.commentSection h4 {
  color: #000000;
  font-family: "GT Walsheim Pro";
  font-weight: bold;
}

.textInputdiv label {
  font-family: "GT Walsheim Pro";
  color: #000;
}

.textareaInput label {
  font-family: "GT Walsheim Pro";
  color: #000;
}

.blankCheckbox {
  color: #8a8a8a;
  font-family: "GT Walsheim Pro";
}

.btn-submit {
  padding: 12px 45px !important;
  cursor: pointer;
  border-radius: 0px;
  background-image: linear-gradient(to right, #543828, #7d4f38);
  color: #fff;
  border: unset;
  font-family: "GT Walsheim Pro";
}

textarea::placeholder {
  color: #717171 !important;
  font-family: "Poppins-Regular";
  font-size: 12px !important;
  font-weight: 500 !important;
}

.conditionrowbc {
  width: 100%;
}

.Content_detail-page {
  margin-top: 15px;
}

.imagedivbottom {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
}