.login .login-inner {
  padding: 75px 24px;
}
p.login-title {
  color: #000000;
  font-family: "Helvetica";
  font-size: 42px;
  font-weight: bold;
}

.login .login-inner .input-cont {
  margin-top: 18px;
  margin-bottom: 6px;
}
.vl {
  /* border-left: 6px solid; */
  height: 241px;
  width: 2px;
  background: #002349;
  position: absolute;
  top: 243px;
  left: 100%;
}
.login .login-inner .input-cont2 {
  margin-top: 10px;
  margin-bottom: 20px;
  /* margin-left: 45px; */
}
.forgert-input-cont {
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 45px;
}

p.signup-title {
  color: #000000;
  font-family: "Helvetica";
  font-size: 42px;
  font-weight: bold;
}

.login .main-para {
  font-family: "Poppins-Regular";
  font-size: 15px;
  color: #000000;
}

.login input {
  width: 100%;
  padding: 8px 14px;
  border: 1px solid #bfbfbf;
  font-family: "Poppins-Regular";
}

.login .login-label {
  font-size: 16px;
  font-family: "Poppins-Regular";
  color: #272727;
  font-weight: 600 !important;
}
.login .right-sec {
  padding: 278px 28px;
  text-align: center;
}

.login .register-btn {
  color: #fff;
  background-image: linear-gradient(to right, #543828, #7d4f38);
  padding: 10px 40px;
  border: unset;
}

/* .login .register-btn:hover{
    color: #543828;
    background-image: unset;
    background-color: transparent;
    border: 1px solid #543828;
} */

.login .signin-btn {
  color: #fff;
  background-image: linear-gradient(to right, #543828, #7d4f38);
  padding: 10px 40px;
  border: unset;
  width: 100%;
  margin-top: 28px;
}
.login .verify-btn {
  color: #fff;
  background-image: linear-gradient(to right, #543828, #7d4f38);
  padding: 15px 49px;
  border: unset;
  width: 37%;
  margin-top: 28px;
}

.preview_img {
  display: flex;
  justify-content: center;
}
p.forget-title {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  font-size: 22px;
  color: #3f2d26;
  font-family: "GT Walsheim Pro";
}
p.forget-main-para {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  font-family: "Poppins-Regular";
  color: #707070;
}
img.forget-image {
  vertical-align: middle;
  border-style: none;
  position: absolute;
  /* right: 0; */
  left: 89%;
  top: 12%;
}
.forget-password-btn {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
/* .login input::after {
    content: "";
    position: absolute;
    display: block;
    height: 12px;
    width: 12px;
    background-image: url("../img/Path8710.png");
    left: 0;
} */
/* 
.login .signin-btn:hover{
    color: #543828;
    background-image: unset;
    background-color: transparent;
    border: 1px solid #543828;
} */

.login .remember-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login .field-set {
  padding-right: 75px;
}

.login .remember-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login .remember-cont input {
  width: auto;
  padding: 0;
  border: unset;
}

.visiblity_icon_posb {
  position: absolute;
  top: 29%;
  right: 6%;
  cursor: pointer;
  color: #707070;
  font-size: 17px !important;
}

.login .remember-cont label {
  font-size: 13px;
  margin-left: 0px;
  font-family: "GT Walsheim Pro";
  margin-top: 10px;
}
.login a {
  font-size: 13px;
  margin-left: 6px;
  font-family: "GT Walsheim Pro";
  margin-top: 10px;
  color: #000;
}

/* .login a:hover{
   text-decoration: underline;
} */

.login .right-title {
  font-size: 30px;
  color: #000;
  font-weight: bold;
  font-family: "Helvetica";
}

.signup-btn {
  width: 48%;
}

.card {
  margin-top: 100px;
  margin-bottom: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.reset-password-container {
  margin-left: 10%;
  margin-top: 30px;
}

.reset-input-cont2 {
  margin-bottom: 18px;
}

.reset-btn {
  color: #fff;
  padding: 15px 49px;
  border: unset;
  margin-top: 28px;
}

.reset-password-btn {
  justify-content: center;
  text-align: center;
}
