.map_sec {
	padding: 40px 0 0;
}

.map_sec .google_map iframe {
	border-radius: 10px;
	margin-top: 70px;
}

/* Map Css Start here */

/* Contact Sec Css Start Here */
.contact_sec {
	padding: 100px 0 60px;
}

.contact_sec .main-para {
	font-size: 14px;
	font-family: "GT Walsheim Pro";
	color: #615c5c;
}

.contact_sec .para {
	color: #615c5c;
	font-size: 14px;
	font-family: "GT Walsheim Pro";
}

.contact_sec .gt-touch {
	color: #002833;
	font-size: 25px;
	font-family: "PlayfairDisplay";
	font-weight: bold;
}

/* .contact_sec .right-sec{
    background: #615c5c0f;
    padding: 40px 30px;
  } */
.contact_sec .title h3 {
	font-size: 30px;
	color: #000000;
	font-weight: 600;
	font-family: Helvetica;
	padding-bottom: 20px;
}

/* .contact_sec .info_box {
  background: #fff;
  border: 1px solid #d4d4d4;
  padding: 22px 21px;
  box-shadow: 0px 0px 15px #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
} */

.contact_sec .info_box .topbar {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.contact_sec .info_box .topbar .iconDv {
	/* background: #1aac7a; */
	background-image: linear-gradient(to right, #88563b, #88563b);
	color: #fff;
	font-size: 14px;
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	margin-right: 10px;
}

.contact_sec .info_box .topbar .iconDv:hover {
	background-image: linear-gradient(to right, #b8b8b8, #313131);
}

.contact_sec .info_box .topbar h4 {
	margin: 0;
	color: #88563b;
	font-size: 22px;
	font-weight: 600;
	font-family: "PlayfairDisplay";
}

.contact_sec .info_box ul.info {
	padding: 0;
	list-style: none;
	margin: 0;
}

.contact_sec .info_box ul.info li span {
	text-transform: uppercase;
	color: #717171;
	font-family: "GT Walsheim Pro";
	font-size: 15px;
	font-weight: 500;
}

.contact_sec .info_box ul.info li a {
	font-size: 14px;
	color: #717171;
	font-family: "Poppins-Regular";
	font-weight: 500;
	text-decoration: none;
	overflow-wrap: break-word;
}

.contact_sec .info_box ul.info li {
	font-size: 14px;
	color: #717171;
	font-family: "Poppins-Regular";
	font-weight: 500;
	text-decoration: none;
	margin: 5px 0;
}

.button-group-submit {
	float: right !important;
}

.contact_sec form {
	padding-top: 80px;
}

.contact_sec form .form-group {
	margin-bottom: 28px;
}

.contact_sec form .form-group .form-control {
	border-radius: 0;
	border: 1px solid #d4d4d4;
	padding: 12px 20px;
	font-family: "Poppins-Regular";
	font-size: 14px;
	color: #717171;
}

.contact_sec form .form-group .form-control::placeholder {
	color: #717171;
	font-weight: 500;
	font-family: "Poppins-Regular";
}

.Contact_Page .contact_sec .info_box {
	background: #fff;
	padding: 22px 21px;
	box-shadow: 3px 3px 12px 2px #dddddd8f;
	margin-bottom: 20px;
}

.Contact_Page .contact_sec form {
	padding-top: 67px;
}

.Contact_Page .contact_sec .title h3 {
	font-size: 24px;
}

.datefiled_css_editprofile {
	/* border-radius: 0px;
  font-family: "GT Walsheim Pro";
  border: 1px solid #b8b8b8;
  font-size: 12px;
  font-weight: 600;
  color: black; */
}

input.form-control::placeholder {
	color: #717171 !important;
	font-family: "Poppins-Regular";
	font-size: 12px !important;
	font-weight: 500 !important;
}

textarea.form-control::placeholder {
	color: #717171;
	font-family: "Poppins-Regular";
	font-weight: 500 !important;
	font-size: 12px !important;
}

.contact_sec textarea::-webkit-input-placeholder {
	border-radius: 0;
	/* border: 1px solid #d4d4d4; */
	/* padding: 12px 20px; */
	border-radius: 0;
	/* border: 1px solid #d4d4d4;
  padding: 12px 20px; */
	font-family: "Poppins-Regular";
	font-size: 13px;
	font-weight: 700 !important;
	color: #717171;
}

.contact_sec form .form-group textarea {
	height: 150px !important;
	resize: none;
}

.contact_sec form .button-group {
	text-align: end;
}

/* .contact_sec form .button-group button.btn {
  padding: 10px 60px;
  cursor: pointer;
  border-radius: 5px;
  background-image: linear-gradient(to right, #543828, #7d4f38);
  color: #fff;
  border: unset;
} */

.button-group {
	/* border-radius: 5px; */
	/* background-image: linear-gradient(to right, #543828, #7D4F38); */
	/* color: #fff; */
	/* border: unset; */
	font-size: 12px;
	/* width: 170px; */
	height: 45px;
	justify-content: center;
	text-align: center;
	align-items: center;
	padding-top: 13px;
}

button.contact-btn {
	padding: 10px 60px;
	cursor: pointer;
	color: #fff;
	border: unset;
	background-image: linear-gradient(to right, #543828, #7d4f38);
}

/* .contact_sec form .button-group button.btn:hover{
    background-image: unset;
    border: 1px solid #51A851 !important;
    color: #51A851;
  } */

.contact_sec form .form-group .form-control::placeholder {
	color: #717171;
	font-family: "Poppins-Regular";
	font-weight: 500 !important;
	font-size: 12px !important;
}
