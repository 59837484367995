/* home service start  */

.service-rates .service-card {
  margin-bottom: 60px;
  /* margin-top: 60px; */
}

.service-rates .sm-tag {
  font-family: "Helvetica";
  color: #88563b;
  font-size: 19px;
  text-align: center;
}

.Service_Page p.service-paragraph {
  font-size: 12px;
}

.Service_Page .service-icon {
  font-size: 17px;
}

.service-name-service {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  color: #3f2d26;
  font-size: 18px;
  font-family: "GT Walsheim Pro";
  align-items: center;
  align-self: center;
}

p.service-name-text {
  color: #000000;
  font-size: 18px;
  font-family: "GT Walsheim Pro";
}

p.service-price-text {
  color: #806c65;
  font-size: 28px;
  font-family: "GT Walsheim Pro";
}

.service-rates .service-card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px 20px;
}

.service-rates .service-card .service-img-box {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.service-rates .service-card .service-img-box img {
  width: 100%;
  height: 280px !important;
  object-fit: cover;
}

.service-rates .service-card .service-title {
  font-size: 18px;
  color: #3f2d26;
  font-weight: 500;
  font-family: "GT Walsheim Pro";
}

.service-rates .service-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.service-rates .service-card .price {
  color: #806c65;
  font-size: 16px;
  font-weight: 500;
  font-family: "GT Walsheim Pro";
}

.service-rates .service-card a {
  color: #806c65;
  font-size: 16px;
  font-weight: 500;
  font-family: "GT Walsheim Pro";
}

.service-book-now-service {
  display: flex;
  margin-top: auto;
}

u.book-now-service {
  color: #3f2d26;
  font-size: 18px;
  font-family: "GT Walsheim Pro";
}

/* maaz */
.right_arrow_serviceRates {
  /* display: none; */
  opacity: 0;
  color: #3f2d26;
  text-decoration: underline;
  position: relative;
  left: 15px;
  top: 2px;
  margin-left: 0.5rem;
  transition: left 1s;
}

.book-now-service:hover+.right_arrow_serviceRates {
  opacity: 1;
  left: 5px;
}

.Service_Page p.service-price-text {
  /* text-decoration: underline; */
}

.service-image {
  width: 30%;
  height: 30%;
  margin-right: 40px;
}

p.service-paragraph {
  color: #000000;
  font-size: 14px;
  font-family: "GT Walsheim Pro";
}

p.service-points-service {
  color: #30231d;
  font-size: 11px;
  font-family: "GT Walsheim Pro";
}

p.service-points-service:nth-child(even) {
  margin-left: 1.5rem;
}

.sevice_details-service {
  width: 70%;
}

.service-name {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.service-text-icon {
  display: flex;
  flex-wrap: wrap;
}

p.service-points {
  font-size: 10px;
}

.service-icon {
  color: #88563b;
  margin-right: 8px;
  font-size: 20px;
}

.social-icon-color i:hover{
  text-decoration: underline;
}

/* @media only screen and (max-width: 768px) {
   p.service-points-service:nth-child(even) {
     margin-left: 0rem !important;
   }
 } */

/* home service end  */